var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __require = typeof require !== "undefined" ? require : (x) => {
  throw new Error('Dynamic require of "' + x + '" is not supported');
};
import $ from "jquery";
import SwiperCore, {
  Swiper,
  Navigation,
  Pagination,
  Thumbs,
  Autoplay,
  Lazy
} from "swiper";
SwiperCore.use([Navigation, Pagination, Thumbs, Autoplay, Lazy]);
export const SwiperSlider = Swiper;
class PageSlider {
  constructor({ defaultOptions } = {}) {
    this.sliders = [];
    this.defaultOptions = defaultOptions;
    this.init();
  }
  init() {
    $(".swiper-container:not(.swiper-container-initialized)").not(".swiper-container-custom").each((i, el) => {
      this.addSlider(el);
    });
  }
  addSlider(el) {
    const $el = $(el);
    let elConfig = $el.data("swiper") || {};
    const $parent = $el.parent();
    const $nextEl = $parent.find(".swiper-button-next");
    const $prevEl = $parent.find(".swiper-button-prev");
    if ($nextEl.length && $prevEl.length && typeof elConfig.navigation === "undefined") {
      elConfig = __spreadProps(__spreadValues({}, elConfig), {
        navigation: {
          nextEl: $nextEl[0],
          prevEl: $prevEl[0]
        }
      });
    }
    this.sliders.push({
      el,
      config: this.mergeConfig(elConfig)
    });
  }
  mergeConfig(config) {
    return __spreadValues(__spreadValues({}, this.defaultOptions), config);
  }
  runSliders() {
    this.sliders.forEach((slider) => {
      const swiper = new Swiper(slider.el, slider.config);
    });
    this.sliders = [];
  }
  refresh() {
    this.init();
    this.runSliders();
  }
}
export default PageSlider;
